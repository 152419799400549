import { Controller } from "@hotwired/stimulus"
// javascript/controllers/disable_controller.js
export default class extends Controller {
  static targets = ["submitButton"]


  // connect() {
  //  console.log("conncted");
  // }

  disable(event) {
    //console.log("disabling...");
    event.preventDefault()
    const button = this.submitButtonTarget
    // const disableMessage = button.dataset.disableWith || "Wait..."
    const disableMessage = "Wait..."
    button.disabled = true
    button.value = disableMessage
    button.classList.remove("my-btn");
    button.classList.remove("my-btn-primary-reserve");
    button.classList.add("btn")
    button.classList.add("btn-dark")
    this.element.submit()
  }
}